$(document).ready(function(){
	if( $('.sizzleVideoContainer:visible').length ){ 
		loadVideo('sizzleVideo',{
				 file:"/assets/images/tmp/nazimegaweapons-assassination.mp4"
				,image:"http://assets-jpcust.jwpsrv.com/thumbs/Qlh3p9ly-720.jpg"
			},false)	
	}
	
	/* 
		Expandable divs for Video
	*/
	$('body').on('click','.expandable .btn',function(){
		var $this = $(this);
		var $expanding = $this.closest('.expandable');
		$expanding.addClass('expanded');
		$expanding.one("otransitionend oTransitionEnd msTransitionEnd transitionend",function(event) {
			// when the css transition ends
			loadVideo($expanding.attr('id'),{
				 file:$this.data('file')
				,image:$this.data('poster')
			},true)	
		});
	})
	/* End expandable video divs */
	
	$('body').on('click','.btnExpand',function(){
		var  $this = $(this)
			,$container = $this.closest('.expandable')
			,$span = $this.find('span');
		
		if($container.hasClass('expanded')){
			$container.removeClass('expanded')
			$span.html( $span.data('open') )
		}else{
			$container.addClass('expanded')
			$span.html( $span.data('close') )
		}
		
	})
	
})


function loadVideo(target, playlist, autostart){
	if(!target || !playlist || !playlist.file) return;
	autostart = (autostart===true)?true : false;
	var playerInstance = jwplayer(target);
	playerInstance.setup({
	     'playlist': playlist
	    ,width: "100%"
	    ,displaytitle: false
	    ,'autostart':autostart
	    ,ga: {} //all that needs to be done to get google analytic tracking.. really
	});
}
