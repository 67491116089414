/* parse the queryString and give an object of the values */
function parseQueryStringToObject(){
	var urlParams = {};
	(function () {
	    var e,
	        d = function (s) { return decodeURIComponent(s.replace(/\+/g, " ")); },
	        q = window.location.search.substring(1),
	        r = /([^&=]+)=?([^&]*)/g;

	    while (e = r.exec(q))
	       urlParams[d(e[1])] = d(e[2]);
	})();

	return urlParams;
}

(function(window, $, undefined){
	$(document).ready(function(){
		/* 
			If the user selects 'request a demo' or 'request a quote',
				show the list of Courses so they can pick one.
		*/
		$('body').on('change','#formSubject',function(){
			var val = $(this).val();
			if( val==="demo" || val==="quote" ){
				$('#formCourseContainer').show();
			}else{
				$('#formCourseContainer').hide();
				$('#formCourse').val(0)
			}
		})		
		
		var qs = parseQueryStringToObject();
		if(qs.s=="demo"){ $('#formSubject').val('demo').trigger('change') }
		if(qs.s=="quote"){ $('#formSubject').val('quote').trigger('change') }
		
		if(qs.c!=""){ $('#formCourse').val(qs.c) }
	})
})(window, jQuery)